/* Temporary code for loadingkalendern-2018 */
.custom-loadingkalendern-note {
  padding: 10px 10px;
  margin: 0 0 8px 0;
  background-color: #fff;
  color: #595959;
  font-size: 1em;
  width: 100%;
  display: flex;
  justify-content: center;
  text-decoration: none;
  box-sizing: border-box;
  font-family: 'Gilroy-Bold', Arial, sans-serif;
  font-weight: normal;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.03);
}

.custom-loadingkalendern-note:hover {
  color: #fd2860;
}
/* Temporary code for loadingkalendern-2018 */
