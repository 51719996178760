.PostItem-container {
  background: transparent;
  display: flex;
  box-sizing: border-box;
  padding: 0 22px 24px 22px;
  width: 100%;
  position: relative;
}

.PostItem-you-got-quoted {
  padding-right: 12px;

  .PostItem-body {
    padding-right: 20px;

    &::before {
      top: 22px;
    }

    &::after {
      content: '';
      background-color: $loading-pink;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 15px;
      border-radius: 0 20px 20px 0;
    }
  }
}

.PostItem-body {
  width: calc(100% - 70px);
  margin-left: 70px;
  box-sizing: border-box;
  position: relative;
  background-color: #fff;
  border-radius: 20px;
  padding: 20px 10px 10px 10px;
  box-shadow: 0 8px 8px rgba(0, 0, 0, 0.03);
}

.PostItem-text {
  padding: 10px;
  text-align: left;
  width: 100%;
  box-sizing: border-box;
  word-break: break-word;
}

.PostItem-text img {
  max-width: 100%;
  max-height: 350px;
}
.PostItem-text p:first-child {
  margin-top: 0;
}
.PostItem-text p:last-child {
  margin-bottom: 0;
}

.editor-preview blockquote + hr,
.PostItem-text blockquote + hr {
  border: none;
}

.PostItem-portrait-container {
  display: inline-block;
  position: absolute;
  left: -111px;  

  img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
  }
}

.PostItem-username {
  display: inline-block;
  vertical-align: super;
  margin-left: 10px;
}

.PostItem-date {
  padding: 8px;
  text-align: left;
  color: #959595;
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: flex-end;
}

.PostItem-TheDate {
  font-size: 0.8em;
  cursor: pointer;
}

.PostItem-TheDate:after {
  content: '– Länk kopierad';
  position: absolute;
  margin-left: 5px;
  opacity: 0;
  transition: all 0.2s;
}

.PostItem-container.copy-to-clipboard .PostItem-TheDate:after {
  opacity: 1;
}

.PostItem-actions {
  margin-top: -5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Row-container {
  background: #f0f0f0;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 12px;
  cursor: pointer;
  text-decoration: none;
}

.Row-container:hover {
  background: rgb(235, 235, 235);
  transition: all .12s ease;
}

/*.Row-white-container {
  background: white;
  width: 100%;
  margin-left: 8px;
  padding: 8px;
}*/

/*.Row-top-row {
  display: flex;
  justify-content: space-between;
}*/

/*.Row-bottom-row {
  display: flex;
  justify-content: flex-start;
}*/

.Row-front-icon {
  height: 32px;
  width: 32px;
  min-width: 32px;
}

/*.Row-creation-text {
  font-size: 0.75em;
  padding-left: 5px;
  color: lightslategrey;
}*/

/*.Row-bubble-container {
  display: flex;
}*/

.Row-bubble-icon {
  height: 27px;
  width: 27px;
  min-width: 27px;
}

.Row-number {
  color: $loading-pink;
  padding-top: 3px;
  width: 50px;
  padding-left: 5px;
  font-size: 1.12em;
  text-align: left;
  text-overflow: ellipsis;
  font-family: 'Gilroy-Regular', sans-serif;
  
  .darkmode & {
    color: $loading-pink-darkmode;
  }
}

.PostItem-editorial {
  max-width: 640px;
  text-align: justify;
  font-size: 1em;
  line-height: 1.5;
}

.PostItem-regular {
  font-size: 1em;
  line-height: 1.5;
}

.PostItem-editorial p img {
  max-width: 100%;
  max-height: 1000px;
}

.PostItem-edit-button {
  margin-left: 5px;
}

.PostItem-edit-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.PostItem-save-button {
  margin-left: 10px;
}

.PostItem-save-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.Picture-hack {
  width: 100%;
  // min-width: 40px;
}

.Empty-hack {
  display: flex;
  justify-content: center;
  align-items:  center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  background-color: #e5e5e5;
  font-family: 'Gilroy-Bold', Arial, sans-serif;
  font-weight: normal;
  font-size: 72px;
  color: white;
}

/* Citat boxen */
.editor-preview blockquote,
.PostItem-regular blockquote {
  background-color: #f7f7f7;
  border-left: 10px solid $loading-pink;
  margin: 0 0 15px 0;
  padding: 15px;
  border-radius: 4px;

  .darkmode & {
    border-left-color: $loading-pink-darkmode;
  }
}

.PostItem-regular blockquote h3 {
  margin: 0 0 -10px 0;
}

/* Citat knappen samt toolboxen */
.PostItem-quote-button {
  margin-left: auto;
}

.PostItem-quote-button a {
  color: $loading-pink;
  text-decoration: none;

  .darkmode & {
    color: $loading-pink-darkmode;
  }
}

.PostItem-quote-button a:hover {
  text-decoration: underline;
}

.PostItem-quote-button span {
  position: relative;
  color: $loading-pink;
  margin-left: 3px;

  .darkmode & {
    color: $loading-pink-darkmode;
  }
}

.PostItem-quote-button span .toolbox {
  display: none;
  position: absolute;
  background-color: $loading-pink;
  color: #fff;
  padding: 7px;
  width: 200px;
  right: 0;
  font-size: 12px;
  z-index: 2;
  border-radius: 6px;

  .darkmode & {
    background-color: $loading-pink-darkmode;
  }
}

.PostItem-quote-button span:hover .toolbox {
  display: block;
}

@media only screen and (max-width: 600px) {
  .PostItem-editorial {
    text-align: left;
  }
}

@include mobil {
  .PostItem-body {
    margin-left: 0;
    width: 100%;
  }

  .PostItem-username {
    vertical-align: 15px;
  }

  .PostItem-portrait-container {
    position: relative;
    left: 0;
    margin-left: 10px;

    img {
      width: 40px;
      height: 40px;
    }
  }
}
