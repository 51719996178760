body {
	background-color: #fafafa;
	margin: 0;
	padding: 0;
}

input,
select {
	box-sizing: border-box;
	width: 100%;
	padding: 11px 5px 11px 10px;
	letter-spacing: 0.5px;
	border: 1px solid #ddd;
	border-radius: 4px;
	font-size: 1em;
	margin: 0 0 10px;
}

select {
	height: 42px;
}

button.aws-btn {
	--button-default-height: 48px;
	--button-default-font-size: 14px;
	--button-default-border-radius: 20px;
	--button-horizontal-padding: 25px;
	--button-raise-level: 5px;
	--button-hover-pressure: 2;
	--transform-speed: 0.185s;
	--button-primary-color: #e94163;
	--button-primary-color-active: #e83156;
	--button-primary-color-dark: #ba3650;
	--button-primary-color-light: #fff;
	--button-primary-color-hover: #e83156;
	--button-primary-border: none;
}

