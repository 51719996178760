.Tooltip-hover {
  position: relative;
  display: inline-block;
}

.Tooltip-hover .Tooltip-text {
  visibility: hidden;
  width: 90px;
  background-color: $loading-pink;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0 3px;

  position: absolute;
  z-index: 1;
  bottom: calc(100% + 5px);
  left: 50%;
  margin-left: -45px;
  font-size: 0.9rem;

  .darkmode & {
    background-color: $loading-pink-darkmode;
  }
}

.Tooltip-hover .Tooltip-text::after {
  content: ' ';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: $loading-pink transparent transparent transparent;
  
  .darkmode & {
    border-color: $loading-pink-darkmode transparent transparent transparent;
  }
}

.Tooltip-hover:hover .Tooltip-text {
  visibility: visible;
}
