input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px white inset;

    body.darkmode & {
        -webkit-box-shadow: 0 0 0px 1000px $loading-dark-darkmode inset;
        -webkit-text-fill-color: $loading-grey-darkmode;
    }
}

.InputField {
    position: relative;
}

.InputField input {
    border: 1px solid #919191;
    border-radius: 20px;
    color: #222;
    font-size: 17px;
    padding: 39px 24px 14px 25px;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
    margin-bottom: 14px;

    body.darkmode & {
        background-color: $loading-dark-darkmode;
        color: $loading-grey-darkmode;
    }
}

.InputField label {
    transition: 0.2s all;
    position: absolute;
    top: 28px;
    left: 26px;
    color: #767676;
    pointer-events: none;
    font-size: 19px;
    white-space: nowrap;

    body.darkmode & {
        color: #8a8a8a;
    }
}
.InputField input:focus ~ label,
.InputField input:not(:placeholder-shown) ~ label {
    top: 13px;
    font-size: 16px;
}

.InputField input ~ label span {
    font-size: 14px;
}

.InputField input:focus,
.InputField input:focus ~ label {
    border-color: #555;
    color: #555;

    body.darkmode & {
        border-color: #ccc;
        color: #ccc;
    }

    .Css-icon::after, 
    .Css-icon::before {
        background-color: #555;
    }
}

.InputField.error input,
.InputField.error label,
.InputField input:not(:placeholder-shown):invalid,
.InputField input:not(:placeholder-shown):invalid ~ label {
    border-color: #e00000;
    color: #e00000;

    body.darkmode & {
        border-color: #ff7070;
        color: #ff7070;
    }
}
