/* WIP WIP WIP WIP */
$mobil-width: 768px;
$desktop-width: 1500px;
$tablet: 991px;

@mixin mobil {
  @media (max-width: #{$mobil-width}) {
    @content;
  }
}

// @mixin big-desktop {
//   @media (min-width: #{$desktop-width}) {
//     @content;
//   }
// }

// @mixin tablet {
//   @media (max-width: #{$tablet}) {
//     @content;
//   }
// }
