.PostModeratorMenu {
  position: absolute;
  top: calc(100% - 45px);
  width: 100%;
  padding: 0px 22px 0px 92px;
  left: 0;
  box-sizing: border-box;
  animation: 0.3s expandDown ease-in-out;
  overflow: hidden;

  .content {
    background-color: #7d394b;
    z-index: 45;
    position: relative;
    border-radius: 0 0 20px 20px;
    padding: 5px 20px 20px 20px;
  }

  .item {
    color: #fff;
    display: inline-block;
    width: 50%;
    font-size: 0.9rem;
    cursor: pointer;
    padding: 5px 0;
    vertical-align: top;

    .icon {
      vertical-align: middle;
      display: inline-block;
      margin-right: 12px;
      text-align: center;
      min-width: 21px;
      min-height: 26px;
    }

    &:hover {
      color: #ccc;
    }
  }

  .headline {
    color: #cdb5bb;
    font-size: $thread-title;
    margin: 16px 0 8px 0;
  }

  &::before {
    background-color: #7d394b;
    content: '';
    display: block;
    height: 30px;
    position: relative;
    z-index: -1;
  }
}

.PostModeratorButton {
  cursor: pointer;
}

@keyframes expandDown {
  0% {
    height: 0%;
  }

  100% {
    height: 200%;
  }
}

@include mobil {
  .PostModeratorMenu {
    padding-left: 22px;
  }
}
