body {
	transition: all 0.4s;
}
/* Legacy, move this out */
body.darkmode {
  background-color: #121212;
  
  .custom-loadingkalendern-note {
    background-color: $loading-dark-darkmode;
    color: $loading-grey-darkmode;
  }

  .custom-loadingkalendern-note:hover {
    color: $loading-pink-darkmode;
  }

  .Cover-text {
    color: $loading-grey-darkmode
  }

	header.Header {
		background-color: $loading-pink-darkmode;
	}

	.Row-you-got-quoted a.Row-bubble-container::after {
		background-color: $loading-pink-darkmode;
	}

	.Row-creation-text,
	.Row-latest-written {
		color: #a1a1a1;
	}

	.Row-title,
	.Row-replies span {
		color: $loading-pink-darkmode;
	}

	// On phone
	.Post-reply textarea,
	.PostItem-text textarea {
		background-color: #121212;
		color: $loading-grey-darkmode;
		border: 1px solid $loading-grey-darkmode;
	}

	.About h1,
	.List-text,
	.PostItem-header,
	.Page-list-header {
		color: $loading-grey-darkmode;
	}

	.Page-list-paging {
		color: $loading-grey-darkmode;
	}

	.Page-list-paging a {
		color: $loading-grey-darkmode;

		&:hover {
			color: $loading-pink-darkmode;
		}
	}

	.PostItem-header .PostItem-header-category {
		color: #a1a1a1;
	}

	.PostItem-body {
		background-color: $loading-dark-darkmode;
		color: $loading-grey-darkmode;
	}

	.PostItem-regular a {
		color: dodgerblue;

		&:visited {
			color: lightcoral;
		}
	}

	.PostItem-date {
		color: #a1a1a1;
	}

	.editor-preview blockquote,
	.PostItem-regular blockquote {
		background-color: #121212;
		border-left-color: $loading-pink-darkmode;
	}

	.PostList-pagination .PostList-boxlink {
		background: $loading-dark-darkmode;
		color: $loading-grey-darkmode;

		&:hover {
			color: $loading-pink-darkmode;
		}
	}

	.PostList-pagination
		div.PostList-boxlink:not(.PostList-boxlink-active):hover {
		color: $loading-grey-darkmode;
	}

	.PostList-pagination .PostList-boxlink.PostList-boxlink-active {
		color: $loading-pink-darkmode;
	}

	.Post-reply label {
		color: $loading-grey-darkmode;
	}

	div.About-text {
		background: $loading-dark-darkmode;
		color: $loading-grey-darkmode;
	}

	.profile-page {
		color: $loading-grey-darkmode;
	}
	.profile-user-info {
		background-color: $loading-dark-darkmode;
	}

	.Contact-text,
	.Privacy-text {
		background-color: $loading-dark-darkmode;
		color: $loading-grey-darkmode;
	}

	.Header-drawer,
	.Header-drawer-active {
		background-color: $loading-dark-darkmode;
	}
	.Header-drawer-link {
		color: $loading-grey-darkmode;
	}

	.Page-list-submit-post {
		h3 {
			color: $loading-grey-darkmode;
		}
		input {
			background-color: $loading-dark-darkmode;
			color: $loading-grey-darkmode;
			border-color: #555;
		}
	}
}
