.Home-item-wall {
  width: 62%;
  float: left;
}

.Home-item-list {
  width: 38%;
  float: left;
}

.Home-item-footer {
  padding-top: 25px;
  clear: both;
}

.Home-container {

}

@media (max-width: 768px ) {
  .Home-item-wall,
  .Home-item-list {
    width: 100%;
  }
  .Home-item-list {
    padding-left: 15px;
    box-sizing: border-box;
  }
}
