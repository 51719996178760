.Cover {
	background-size: cover;
	background-position: center;
	display: flex;
	align-items: flex-end;
	margin-bottom: 12.5px;
	margin-left: 12.5px;
	cursor: pointer;
	text-decoration: none;
	border-radius: 20px;
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.03);
	overflow: hidden;
	position: relative;
	aspect-ratio: 16 / 9;
	width: 100%;
	// -webkit-mask-image: -webkit-radial-gradient(white, black);

	.Cover-content {
		&::after {
			content: '';
			background-color: #fff;
			display: block;
			position: absolute;
			z-index: -1;
			width: 7px;
			height: calc(100% - 100px);
			top: 62px;
			padding: 0.25em 0;
			left: 22px;

			.darkmode & {
				background-color: $loading-grey-darkmode;
			}
		}

		.tag {
			margin-left: -6px;
		}

		.title {
			text-transform: uppercase;
			background-color: #fff;
			padding: 5px 8px 3px 6px;
			margin-left: -6px;

			.darkmode & {
				background-color: $loading-grey-darkmode;
			}

			// &::after {
			//  content: '';
			//  background-color: #fff;
			//  display: block;
			//  position: absolute;
			//  z-index: -1;
			//  width: 7px;
			//  height: 100%;
			//  top: -0.25em;
			//  padding: 0.25em 0;
			//  left: -0.2em;

			//  .darkmode & {
			//    background-color: $loading-grey-darkmode;
			//  }
			// }
		}

		.byline {
			background-color: #fff;

			.darkmode & {
				background-color: $loading-grey-darkmode;
			}
		}
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: inherit;
		background-size: cover;
		transform-origin: center;
		transition: transform 0.2s ease;
		border-radius: 80px; // Safari fix
	}

	&:focus,
	&:hover {
		&::after {
			transform: scale(1.05);
		}
	}
}

.Cover-content {
	position: relative;
	z-index: 1;
	padding: 25px 28px;
	color: #000;

	.byline {
		position: relative;
		z-index: 1;
		display: inline-block;
		font-size: 1.1rem;
		padding: 3px 5px;
		margin-left: -6px;
		margin-top: -2px;
	}

	.title {
		position: relative;
		font-size: 1.8rem;
		line-height: 2.2rem;
		display: inline;
		white-space: pre-wrap;
		padding-right: 8px;

		.darkmode & {
			border-color: $loading-grey-darkmode;
		}
	}

	.tag {
		font-size: 1.2rem;
		display: inline-block;
		margin-bottom: 12px;
		padding: 3px 6px 0px 6px;
		color: #fff;
		background-color: $loading-pink;

		.darkmode & {
			background-color: $loading-pink-darkmode;
		}
	}
}

.Cover-small {
	display: flex;
	margin-bottom: 12.5px;
	margin-left: 12.5px;
	cursor: pointer;
	text-decoration: none;
	border-radius: 20px;
	box-shadow: 0px 8px 8px rgb(0 0 0 / 3%);
	overflow: hidden;
	position: relative;
	width: 100%;
	// -webkit-mask-image: -webkit-radial-gradient(white, black);

	.Cover-content {
		width: 100%;

		.title {
			font-size: 1.4rem;
			line-height: 1.6rem;
			display: inline-block;
			border: none;
		}

		.byline {
			margin-top: 5px;
			font-size: 1rem;
		}

		.tag {
			margin-bottom: 18px;
		}

		.darkmode & {
			color: $loading-grey-darkmode;
		}
	}

	.Cover-image {
		width: 100%;
		max-width: 350px;
		background-size: cover;
		background-position: center;
		position: relative;
		overflow: hidden;
		aspect-ratio: 16 / 9;

		&::after {
			overflow: hidden;
			content: '';
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			background: inherit;
			background-size: cover;
			transform-origin: center;
			transition: all 0.2s ease;
			border-radius: 40px; // Safari fix
		}
	}

	&:focus,
	&:hover {
		& .Cover-image::after {
			transform: scale(1.05);
		}
	}

	&:hover {
		// box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

		&:after {
			left: 0;
		}
	}

	&::after {
		content: '';
		background: linear-gradient(
			90deg,
			rgba(253, 40, 96, 0.13) 0%,
			rgba(255, 255, 255, 1) 50%
		);
		width: 200%;
		height: 100%;
		position: absolute;
		top: 0;
		bottom: 0;
		left: -100%;
		right: 0;
		z-index: -1;
		transition: all 0.4s;

		.darkmode & {
			background: linear-gradient(
				90deg,
				rgba(255, 255, 255, 0.13) 0%,
				#1e1e1e 50%
			);
		}
	}
}

@include mobil {
	.Cover-content {
		.title {
			font-size: 1.4rem;
			line-height: 1.8rem;
		}

		.tag,
		.byline {
			font-size: 1.1rem;
		}
	}

	.Cover-small .Cover-content {
		.title {
			font-size: 1.2rem;
			line-height: 1.4rem;
		}

		.tag,
		.byline {
			font-size: 1rem;
		}
	}

	.Cover-content::after {
		top: 58px;
	}
}
