.Header {
  background-color: #fd2860;
  height: 100px;
  padding: 0 40px;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.Header-logo {
  height: 50px;
}

.Header-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
}

.Header-icons {
  padding-right: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Header-icon-link {
  height: 50px;
  width: 50px;
  position: static;
  text-decoration: none;
  padding: 0 5px;
  line-height: 1rem;
}

.Header-icon-text {
  transition: opacity 0.3s;
  color: white;
}

.Header-icon-link .Header-icon-text {
  opacity: 0;
}

.Header-icon-link:hover .Header-icon-text {
  opacity: 1;
}

.Header-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1px; /* Because of the icons are not really 50px height */
}

.Header-link {
  text-decoration: none;
  font-family: 'Gilroy-Regular', sans-serif;
  color: white;
  padding-bottom: 3px;
}

.Header-link:hover {
  text-decoration: underline;
}

.Header-link-active {
  text-decoration: underline;
  color: lightgrey;
  padding-bottom: 3px;
}

.Header-drawer {
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  transition: 0.5s;
  transform: translateX(100%);
  z-index: 1;
}

.Header-drawer-active {
  z-index: 100;
  background: white;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px;
  height: 100%;
  transition: 0.5s;
  transform: translateX(0%);
}

.Header-drawer-link {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 30px;
  text-decoration: none;
  margin: 8px 20px;
  padding: 8px;
  border-radius: 5px;
  color: black;
}

.Header-drawer-link:hover {
  background: lightgrey;
}

.Header-drawer-icon {
  width: 30px;
  height: 30px;
  padding-right: 15px;
}

.Header-drawer-section {
  margin-top: 20px;
}

.Header-drawer-section hr {
  margin: 0 20px;
  background-color: lightgrey;
  border: 0;
  height: 1px;
}

.Header-drawer-text {
  color: #fd2860;
}

.Header-hamburger {
  display: none;
  height: 50px;
  width: 50px;
  cursor: pointer;
  margin-left: 10px;
}

.Header-overlay {
  z-index: 10;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  transition: 0.5s;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.Header-notification,
.Header-burger-notification {
  height: 50px;
  width: 50px;
  background-color: #fff;
  border-radius: 100%;
  /*position: absolute;*/
  color: #fd2860;
  font-size: 1.7rem;
  line-height: 3rem;
}

.Header-notification,
.Header-burger-notification {
  position: absolute;
  top: 50px;
  right: 50px;
  zoom: 0.5;
  line-height: 1.8em;
}

.Header-burger-notification {
  display: none;
  text-align: center;
}

.Header-search {
  margin-left: auto;
  margin-right: 15px;
  padding: 15px 0 15px 15px;
  cursor: pointer;
}

.Header-search-box {
  // position: absolute;
  position: fixed;
  width: 0;
  right: 0;
  top: 0;
  // height: 0;
  overflow: hidden;
  transition: 0.3s;
  z-index: 3;

  &.Header-search-active {
    // height: 42px;
    // height: 100%;
    overflow: visible;
    width: 100%;
  }

  input {
    height: 100px;
    margin: 0;
    background-color: $loading-pink;
    border: none;
    border-radius: 0;
    outline-color: $loading-pink;
    font-size: 32px;
    padding: 16px 120px 16px 78px;
    box-sizing: border-box;
    color: #fff;

    &:focus,
    &:focus-visible {
      // border-color: #000;
      outline: 0;
      border-radius: 0;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }

    .darkmode & {
      background-color: $loading-pink-darkmode;
    }
  }
}

.Search-close {
  // background-color: aqua;
  position: absolute;
  right: 0;
  top: 0;
  height: 100px;
  width: 100px;
  cursor: pointer;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    height: 5px;
    width: 35px;
    background-color: #fe87a3;
    border-radius: 8px;
    top: 47px;
    left: 34px;
    transition: all 0.2s;
  }

  &::after {
    transform: rotate(45deg);
  }

  &::before {
    transform: rotate(135deg);
  }

  &:hover {
    &::after,
    &::before {
      background-color: #fff;
    }
  }
}

.Search-results {
  position: fixed;
  background: rgba(0,0,0,0.8);
  top: 0;
  z-index: 2;
  overflow: auto;
  height: calc(100vh - 100px);
  padding-top: 100px;
  left: 0;
  right: 0;

  .Search-loader {
    margin: auto;
    text-align: center;
    padding: 32px;
    font-size: 32px;
  }
}

.Search-results .Search-posts {
  max-width: 750px;
  margin: 0 auto;
  overflow: hidden;
  border-radius: 0 0 8px 8px;
}

.Search-results .Search-posts a {
  // display: flex;
  display: block;
  align-items: center;
  padding: 16px;
  margin: 16px;
  color: #000;
  text-decoration: none;
  border-radius: 20px;
  background-color: #fff;

  .darkmode & {
    background-color: $loading-dark-darkmode;
    color: $loading-grey-darkmode;
  }

  &:hover {
    // background-color: #fafafa;
  }

  .name {
    margin-left: 8px;
    white-space: nowrap;
    display: flex;
    align-items: center;

    img {
      margin-right: 11px;
      border-radius: 8px;
    }
  }

  .text {
    margin-top: 8px;

    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;

    .highlight {
      // background-color: yellow;
    }
  }

  &:last-child {
    margin-bottom: 132px;
  }
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*='Header-nav'] {
    display: none;
  }
  [class*='Header-hamburger'],
  .Header-burger-notification {
    display: block;
  }
}
