.PostModeratorButton {
  position: relative;

  & > .PostModeratorMenu {
    position: absolute;
    top: 35px;
  }
}

.PostModeratorButton-text:hover {
  cursor: pointer;
  text-decoration: underline;
}
