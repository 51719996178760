body.darkmode {
	.editor-preview,
	.editor-preview-side,
	.CodeMirror {
		background-color: $loading-dark-darkmode;
		border-color: #555;
		color: $loading-grey-darkmode;
	}

	.editor-toolbar {
		background-color: #131313;
		border-color: #555;

		a {
			color: $loading-grey-darkmode !important;
			&:hover {
				color: $loading-dark-darkmode !important;
			}
		}
	}

	.CodeMirror-selected,
	.CodeMirror-focused .CodeMirror-selected,
	.CodeMirror-line::selection,
	.CodeMirror-line > span::selection,
	.CodeMirror-line > span > span::selection {
		background-color: #333;
	}

	.editor-toolbar.disabled-for-preview a:not(.no-disable) {
		background-color: $loading-dark-darkmode;
		opacity: 0.2;
	}

	.CodeMirror-cursor {
		border-left-color: $loading-grey-darkmode;
	}

	.editor-toolbar a.active,
	.editor-toolbar a:hover {
		background: none;
		color: $loading-grey-darkmode !important;
		color: $loading-grey-darkmode !important;
	}
}
