.Loader-box {
	color: transparent;
}

.Loader-box {
	background: linear-gradient(270deg, #eee, #ccc);
	background-size: 800% 800%;

	animation: GradientFade 1s ease infinite;

	.darkmode & {
		background: linear-gradient(270deg, #333, #222);
		background-size: 800% 800%;
	}
}

@keyframes GradientFade {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

/* WHEN DEVELOPING, no scrollbar on localhost but on loading.se */
// html {
// 	overflow: -moz-scrollbars-vertical;
// 	overflow-y: scroll;
// }
// ::-webkit-scrollbar {
// 	-webkit-appearance: none;
// }

// ::-webkit-scrollbar:vertical {
// 	width: 15px;
// }
