.Page-list-width {
	max-width: 770px;
	margin: 0 auto;
}

.Redaktionellt-page {
	position: relative;
}

.Page-sorting {
	padding: 0 50px;

	.Sort,
	.Articles {
		h3 {
			margin-bottom: 4px;

			.darkmode & {
				color: $loading-grey-darkmode;
			}
		}
	}

	.Sort-pill {
		display: inline-block;
		background: #fff;
		padding: 6px 10px 4px 10px;
		text-align: center;
		letter-spacing: 1px;
		text-decoration: none;
		margin: 4px;
		font-size: $thread-title;
		color: $loading-grey;
		border-radius: 20px;
		min-width: 30px;
		box-sizing: border-box;
		box-shadow: 0 8px 8px rgba(0, 0, 0, 0.03);
		cursor: pointer;

		.darkmode & {
			background-color: $loading-dark-darkmode;
			color: $loading-grey-darkmode;
		}

		&:hover,
		&.active {
			color: $loading-pink;

			.darkmode & {
				color: $loading-pink-darkmode;
			}
		}
	}

	.Redaktionen {
		margin: 16px -24px 0 -24px;
	}

	.Redaktionen-box {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		box-sizing: border-box;
		border-radius: 20px;
		box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.03);
		background-color: #fff;
		padding: 24px;

		.darkmode & {
			background-color: $loading-dark-darkmode;
			color: $loading-grey-darkmode;
		}

		p {
			width: 65%;
			margin: 0;
		}

		img {
			width: 30%;
			height: 30%;
		}

		h3 {
			margin-bottom: 0;
		}

		a {
			color: $loading-grey;
			text-decoration: none;

			&:hover {
				color: $loading-pink;
			}

			.darkmode & {
				color: $loading-grey-darkmode;

				&:hover {
					color: $loading-pink-darkmode;
				}
			}
		}
	}
}

@media (min-width: 1530px) {
	.Page-sorting {
		position: absolute;
		width: 340px;
		right: -364px;
		padding: 0;

		.Redaktionen {
			margin: 32px 0 0 0;
		}
	}
}
