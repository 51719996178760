.pStyle2 {
  text-decoration: none;
}

.pStyle3 {
  display: flex;
  padding-left: 20px;
  margin: 0;
  text-decoration: none;
  color: black;
}

.pStyle4 {
  display: flex;
  padding-left: 0px;
  margin: 0;
  text-decoration: none;
  color: black;
}

.Post-container {
  max-width: 750px;
  margin: auto;
}

.PostItem-header {
  padding: 30px 40px 30px 52px;
  font-size: 2.3rem;
  color: black;
  text-align: left;

  .PostItem-header-category {
    font-size: 1.2rem;
    color: #595959;

    .Css-icon {
      vertical-align: top;
      margin-right: 8px;
    }
  }
}

.PostList-link {
  text-decoration: none;
  color: black;
  padding-right: 12px;
}

.PostList-pagination {
  font-size: 1rem;
  float: right;
  padding-right: 32px;

  .PostList-boxlink {
    display: inline-block;
    background: #fff;
    padding: 6px 10px 4px 10px;
    text-align: center;
    letter-spacing: 1px;
    text-decoration: none;
    margin: 2px;
    color: #000;
    border-radius: 20px;
    min-width: 30px;
    box-sizing: border-box;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.03);

    &.PostList-boxlink-active {
      color: $loading-pink;
    }

    &:hover {
      color: $loading-pink;
    }
  }

  div.PostList-boxlink:not(.PostList-boxlink-active):hover {
    color: #000;
  }
}

.PostItem-header .PostList-pagination {
  padding-right: 0;
}

.Post-reply {
  text-align: left;
}

.Post-reply textarea {
  width: calc(100% - 20px);
  border: 0;
  padding: 10px;
  text-align: left;
  font-family: 'Gilroy-Regular', Arial, sans-serif;
  font-size: 1rem;
}

.Post-reply input {
  margin-bottom: 10px;
}

.Post-reply label {
  display: block;
  font-family: 'Gilroy-Regular', sans-serif;
  font-size: 1.2em;
  padding-bottom: 10px;
}

@include mobil {
  .PostList-pagination {
    text-align: center;
    float: unset;
    padding: 0;
    margin: 30px 0 0 0;
  }

  .PostList-boxlink {
    padding: 8px 12px;
  }

  .PostItem-container {
    display: block;    
  }
  
  .PostItem-header {
    padding-left: 40px;
  }
}
