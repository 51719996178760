.Header-profile-wrapper {
  line-height: 0;
}

.Header-profile,
.Header-profile-wrapper img {
  border-radius: 100%;
  overflow: hidden;
  width: 47px; /* Because of the icons are not really 50px height */
  height: 47px; /* Because of the icons are not really 50px height */
}

.Header-profile-wrapper.default-avatar {
  background-color: #fafafa;
  border-radius: 100%;
  width: 47px;
  height: 47px;
}

.Header-profile-wrapper.default-avatar img {
  width: 28px;
  height: 28px;
  border-radius: 0;
  margin-top: 12px;
}