@import '../fonts/font-gilroy';

body,
.InputField input,
.PostList-pagination,
.Cover-content .byline,
.Header-search-box input {
	font-family: 'Gilroy-Regular', Arial, sans-serif;
	font-weight: normal;
	letter-spacing: 0.02em;
}

h1,
h2,
strong,
.Row-title,
.List-text,
.Sort-pill,
.InputField,
.Row-replies,
.Cover-content,
.profile-logout,
.PostItem-header,
.Page-sorting h3,
.Page-list-paging,
.PostModeratorMenu,
.PostItem-username,
.Search-posts .name,
.PostItem-regular blockquote h3,
.Page-list-submit-post .editor-show-button,
.Search-results .Search-posts a .text .highlight {
	font-family: 'Gilroy-Bold', Arial, sans-serif;
	font-weight: normal;
}

h3,
h4,
h5,
h6 {
	font-weight: normal;
}
