// Icons from https://css.gg/quote

.PostItem-you-got-quoted .PostItem-body::before,
.Row-you-got-quoted .Row-bubble-container::before {
    content: '';
    display: block;
    box-sizing: border-box;
    position: absolute;
    width: 8px;
    height: 6px;
    border-left: 3px solid;
    border-right: 3px solid;
    right: 12px;
    top: 30%;
    transform: skewX(-20deg);
    color: #fff;
    z-index: 1;
}
