/**
 * @license
 * MyFonts Webfont Build ID 3860104, 2020-01-27T03:59:18-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 * 
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * 
 * 
 * Licensed pageviews: 100,000
 * Webfonts copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 * 
 * © 2020 MyFonts Inc
*/

@font-face {
	font-family: 'Gilroy-Regular';
	src: url('../../assets/font-gilroy/3AE688_0_0.eot');
	src: url('../../assets/font-gilroy/3AE688_0_0.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font-gilroy/3AE688_0_0.woff2') format('woff2'),
		url('../../assets/font-gilroy/3AE688_0_0.woff') format('woff'),
		url('../../assets/font-gilroy/3AE688_0_0.ttf') format('truetype');
	font-display: swap;
}

@font-face {
	font-family: 'Gilroy-Bold';
	src: url('../../assets/font-gilroy/3AE688_1_0.eot');
	src: url('../../assets/font-gilroy/3AE688_1_0.eot?#iefix') format('embedded-opentype'),
		url('../../assets/font-gilroy/3AE688_1_0.woff2') format('woff2'),
		url('../../assets/font-gilroy/3AE688_1_0.woff') format('woff'),
		url('../../assets/font-gilroy/3AE688_1_0.ttf') format('truetype');
	font-display: swap;
}
