.Signup-page {
	max-width: 475px;
	margin: 0 auto 0;
	padding: 10px 27px;

	form {
		position: relative;
	}

	.aws-btn {
		margin-top: 22px;
	}

	a {
		color: #000;

		&:hover {
			text-decoration: none;
		}

		body.darkmode & {
			color: $loading-grey-darkmode;
		}
	}

	body.darkmode & {
		color: $loading-grey-darkmode;
	}
}

.Signup-header {
	padding: 27px 27px 32px 27px;

	h1 {
		margin-top: 0;
	}
}

.Signup-column {
	padding: 27px 27px 32px 27px;
	display: flex;

	.aws-btn {
		flex-shrink: 0;
		margin: auto;
	}

	p {
		margin-left: 16px;
	}
}

.ReCAPTCHA.error > div > div {
	border-left: 8px solid #e43731;

	body.darkmode & {
		border-color: #ff7070;
	}
}

.ReCAPTCHA > div > div {
	margin: 0 auto;
}

.grecaptcha-badge { visibility: hidden; }
