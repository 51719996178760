.Footer-container {
  padding: 12.5px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.Footer-social {
  display: flex;
  width: 100%;
  justify-content: space-between;

}

.Footer-social-group {
  display: flex;
  flex-wrap: wrap;
  margin-left: 117px;
}

.Footer-patreon {
  display: flex;
  align-items: flex-start;
  margin-right: 117px;
  text-decoration: none;
}

.Footer-icon {
  width: 54.6px;
  height: 54.6px;
  font-size: 54.6px;
  padding-bottom: 20px;
}

.Footer-icon:not(:last-child) {
  margin-right: 39.7px;
}

.Patreon-image {
  width: 48px;
  height: 46px;
  padding-top: 5px;
  margin-left: 39.7px;
  object-fit: contain;
}

.Footer-bubble-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-left: 30px;
  height: 76px;
}

.Footer-FAIcon {
  vertical-align: top;
}

.Footer-bubble {
  background-color: #FF424D;
  color: white;
  font-family: 'Gilroy-Regular', sans-serif;
  height: 23px;
  padding: 19px 16px 13px;
  white-space:nowrap;
}

.Patreon-bubble-point {
  height: 19px;
  width: 19px;
  margin-left: 21px;
}


.Footer-links {
  margin-left: 117px;
  padding-top: 22px;
  color: #707070;
}

.Footer-captcha {
  color: #707070;
}

.Footer-link {
  color: #707070;
  text-decoration: none;
  padding-right: 100px;
}

.Footer-link:hover {
  text-decoration: underline;
}
.Footer-links div {
  display: inline;
}

@media (max-width: 768px) {
  .Footer-social {
    flex-direction: column-reverse;
    align-items: center;
  }
  .Patreon-image {
    margin-left: 0;
  }
  .Footer-links {
    margin-left: 0;
    display: flex;
    width: 100% ;
    padding-top: 12.5px;
    flex-direction: column;
    justify-content: center;
  }
  .Footer-social-group {
    margin-left: 0;
    justify-content: center;
  }
  .Footer-patreon {
    margin-right: 0;
    margin-bottom: 24px;
  }
  .Footer-link {
    color: #707070;
    text-decoration: none;
    padding-right: 0;
  }
  .Footer-links div {
    display: block;
  }
  .Footer-links div:last-child {
    margin-top: 12.5px;
  }
  .Footer-icon {
    width: 35px;
    height: 35px;
    font-size: 35px;
  }
  .Footer-icon:not(:last-child) {
    margin-right: 20px;
  }
  .Footer-bubble-container {
    margin-left: 25px;
  }
}
