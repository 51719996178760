.Forgot-container {
  max-width: 475px;
  margin: 0 auto 0;
  padding: 10px 27px;

  .aws-btn {
    margin-top: 18px;
    margin-left: 27px;
  }

	body.darkmode & {
		color: $loading-grey-darkmode;
	}
}

.Forgot-column {
  padding: 27px 27px 32px 27px;

  h1 {
    margin-top: 0;
  }
}
