.Login-container {
  max-width: 475px;
  margin: 0 auto 0;
  padding: 10px 27px;

  form {
    position: relative;
  }

  body.darkmode & {
    color: $loading-grey-darkmode;
  }
}

.Login-column {
  display: flex;
  padding: 27px 27px 32px 27px;

  .aws-btn {
    flex-shrink: 0;
    margin-right: auto;
  }

  h1 {
    margin-top: 0;
  }
}

.Reset-link {
  color: #000;
  margin: auto 0 auto 16px;

  &:hover {
    text-decoration: none;
  }

  body.darkmode & {
    color: $loading-grey-darkmode;
  }
}

@include mobil {
  .Login-first {
    flex-direction: column;
    align-items: center;

    img {
      margin-top: 16px;
    }
  }
}
