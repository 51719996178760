.Page-list-header {
	padding: 0 50px;

	h1 {
		font-size: 2.250em;
		margin-bottom: 0;
	}

	.Css-icon {
		vertical-align: top;
		margin-right: 10px;
	}
}

.Page-list {
	max-width: 770px;
	margin: 0 auto;
}

.Page-list-paging {
	font-size: 18px;
	text-align: right;
	padding-right: 30px;

	a {
		text-decoration: none;
		color: #000;

		&:hover {
			color: $loading-pink;
		}
	}

	img {
		height: 18px;
		display: inline-block;
		vertical-align: text-bottom;
	}
}

.Page-list-submit-post {
	max-width: 800px;
	margin: 0 auto;

	fieldset {
		border: none;
		position: relative;
	}

	.aws-btn {
		margin-top: 8px;
	}
}

.Page-list-submit-post .editor-user {
	margin-bottom: 16px;

	display: none;
	align-items: flex-end;

	> div {
		flex: 1;
		padding: 10px;
	}

	select {
		margin-bottom: 0;
	}

	.react-datetime-picker {
		z-index: 2;
	}

	.dropzone, .dropzone--isActive {
		width: 100%;
		height: 120px;
	}

}

.Page-list-submit-post .editor-show-button {
	color: $loading-pink;
	text-align: right;
	display: block;
	padding-bottom: 15px;
	margin-top: -40px;
	margin-right: 4px;
	position: relative;
	cursor: pointer;

	&::before {
		content: '+';
		font-size: 1.3rem;
		line-height: 1.4rem;
		position: absolute;
		margin-left: -17px;
	}
}

#editor-toggle {
	position: absolute;
	width: auto;
	top: 30px;
	right: 124px;
	opacity: 0;

	&:checked ~ .editor-user {
		display: flex;
	}

	&:checked ~ .editor-show-button::before {
		content: '-';
		line-height: 1.3rem;
	}
}
