.Games-container {
  max-width: 770px;
  margin: 0 auto;
}

.Row-links-container {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  margin: 10px 25px;
  border-radius: 20px;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.03);
  transition: all 0.4s;
  position: relative;
  overflow: hidden;

  a:first-child {
    padding: 12px 0 13px 27px;
  }

  a {
    padding: 12px 0;
  }

  a:last-child {
    padding: 12px 27px 13px 0;
  }

  &:hover {
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);

    &:after {
      left: 0;
    }
  }

  &:after {
    content: '';
    background: linear-gradient(90deg, rgba(253, 40, 96, 0.13) 0%, rgba(255, 255, 255, 1) 50%);
    width: 200%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    right: 0;
    z-index: -1;
    transition: all .4s;
    
    .darkmode & {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.13) 0%,
        #1e1e1e 50%
      );
    }
  }
}

.Row-forum-container {
  display: flex;
  flex-grow: 2;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  text-decoration: none;
}

.Row-white-container {
  width: 100%;
  max-width: 100%;
  text-align: left;
  padding-right: 8px;
}

.Row-creation-text {
  font-size: $sub-title;
  color: $loading-grey;
  margin-top: 3px;
  height: 17px;
}

.Row-bubble-container {
  display: flex;
  text-align: right;
  text-decoration: none;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
}

.Row-title {
  color: $loading-pink;
  font-size: $thread-title;
  text-align: left;
  text-overflow: ellipsis;
}

.Row-latest-written {
  font-size: $sub-title;
  color: $loading-grey;
  margin-top: 3px;
  position: absolute;
  right: 26px;
  bottom: 13px;

  max-width: 50%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  direction: rtl;
}

.Row-replies {
  span {
    font-size: $thread-title;
    color: $loading-pink;
    margin-right: 5px;
  }

  .Icon {
    width: 19px;
    vertical-align: top;
  }
}

.Row-you-got-quoted {
  margin-right: 10px;

  a.Row-bubble-container {
    padding-right: 42px;

    &::after {
      content: '';
      background-color: $loading-pink;
      color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 15px;
    }
  }

  .Row-latest-written {
    right: 42px;
  }
}

.Row-you-have-read {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);

  &:hover {
    -webkit-filter: unset;
    filter: unset;
  }
}
