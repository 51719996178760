.Css-icon {
	position: relative;
	height: 100%;
	width: 100%;
	display: inline-block;

	&::before {
		border-radius: 100%;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		content: '';
	}

	&[data-color='white']::before {
		background-color: #fff;
	}

	&[data-color='grey']::before {
		background-color: $loading-grey;
	}

	&[data-color='pink']::before {
		background-color: $loading-pink;

		.darkmode & {
			background-color: $loading-pink-darkmode;
		}
	}
}

.Css-icon[data-type='pause']::before {
	clip-path: polygon(
		22% 100%,
		100% 100%,
		100% 0%,
		0% 0%,
		0% 100%,
		46% 100%,
		31% 84%,
		31% 16%,
		48% 16%,
		48% 84%,
		52% 84%,
		52% 16%,
		69% 16%,
		69% 84%,
		22% 84%
	);
}

.Css-icon[data-type='play']::before {
	clip-path: polygon(
		80% 50%,
		26% 86%,
		33% 100%,
		100% 100%,
		100% 0%,
		0% 0%,
		0% 100%,
		42% 100%,
		30% 85%,
		30% 17%
	);
}

.Css-icon[data-type='stop']::before {
	clip-path: polygon(
		0% 100%,
		100% 100%,
		100% 0%,
		0% 0%,
		0% 100%,
		17% 83%,
		17% 17%,
		83% 17%,
		83% 83%,
		17% 83%,
		33% 67%,
		67% 67%,
		67% 33%,
		33% 33%,
		33% 67%
	);
}

/*
https://www.uplabs.com/posts/clip-path-generator
*/

.Header-active .Css-icon[data-type='ghost-search'] {
	position: fixed;
	opacity: 1;
	left: 24px;
}

.Css-icon[data-type='ghost-search'] {
	position: fixed;
	opacity: 0;
	left: 100%;
	z-index: 4;
	transition: all 0.3s;
}

.Css-icon[data-type='search'] {
	display: block;
}

.Css-icon[data-type='search']::before,
.Css-icon[data-type='ghost-search']::before {
	background-color: transparent;
	height: 50%;
	width: 50%;
	border-radius: 100%;
	border: 5px solid #fff;
}

.Css-icon[data-type='search']::after,
.Css-icon[data-type='ghost-search']::after {
	position: absolute;
	right: 0;
	bottom: 0;
	content: '';
	height: 5px;
	width: 15px;
	background-color: #fff;
	border-radius: 10px;
	transform: rotate(45deg);
	transform-origin: 100% 0%;
}


.Css-icon[data-type='input-cross'] {
	margin: 0 3px;
	vertical-align: bottom;
	padding-bottom: 1px;
}

.Css-icon[data-type='input-cross']::after,
.Css-icon[data-type='input-cross']::before {
	transition: 0.2s all;
	position: absolute;
	content: '';
	height: 3px;
	width: 12px;
	background-color: #e00000;
	border-radius: 10px;
	transform: rotate(45deg);
}

.Css-icon[data-type='input-cross']::before {
	transform: rotate(135deg);

}
