.profile-page {
	max-width: 770px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	padding: 15px;
}

.profile-user-info {
	background-color: #fff;
	border-radius: 20px;
	width: 100%;
	padding: 24px 27px;
	margin-top: 27px;
	box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.03);
	display: flex;
	box-sizing: border-box;

	h2 {
		margin: 12px 0 2px 0;
	}

	p {
		margin: 0 0 4px 0;
		overflow-wrap: break-word;
	}

	strong {
		display: block;
		margin-top: 12px;
	}
}

.profile-information {
	padding-left: 20px;
	flex-grow: 2;
}

.profile-notification {
	width: 100%;

	h1 {
		font-size: 2.25em;
		margin-bottom: 0;
		padding-left: 25px;
	}
	h1 .Css-icon {
		vertical-align: top;
		margin-right: 10px;
	}

	.Row-links-container {
		margin-left: 0px;
	}
}

.profile-logout {
	color: $loading-pink;
	cursor: pointer;
	margin-bottom: auto;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}
}

.profile-patreon-icon {
  width: 16px;
  height: 16px;
  padding-top: 5px;
	padding-right: 8px;
  object-fit: contain;
}

.profile-patreon {
	color: $loading-pink;
	cursor: pointer;
	margin-bottom: auto;
	text-decoration: none;
	font-family: 'Gilroy-Bold', Arial, sans-serif;

	&:hover {
		text-decoration: underline;
	}
}

.profile-patreon-info {
	display: inline;
	margin-bottom: auto;
	font-family: 'Gilroy-Regular', Arial, sans-serif;
}

@include mobil {
	.profile-page {
		flex-direction: column;
	}

	.profile-user-info,
	.profile-notification {
		width: 100%;
	}

	.profile-user-info {
		flex-direction: column;
	}

	.profile-notification .Row-links-container {
		margin-left: 0;
	}
}
